<template>
<slot></slot>
</template>

<script>
export default {
  name: "classroom",

  mounted() {
    Intercom('shutdown');
  }
}
</script>

<style scoped>

</style>
